import { getAction,getByIdAction,postAction,putAction } from '@/api/common/request'
const baseURL = 'xun/fund/xunBankBorrow';
//调用 import { borrowList, cancellMoney } from "@/api/capital/bank/bankBorrow";

//借支记录表
const borrowList = (params) => getAction(baseURL+"/list", params);
//新增借款
const addBorrow= (params) => postAction(baseURL+"/add",params);
//作废
const cancellMoney=(params)=>getByIdAction(baseURL+"/cancellMoney",params);
//查看所有作废的详情信息
const getCancellById=(params)=>getByIdAction(baseURL+"/getCancellById",params);
//详情
const selectBorrowById=(params)=>getByIdAction(baseURL+"/selectBorrowById",params);
//编辑借款
const editBorrow=(params)=>postAction(baseURL+"/edit",params);
//归还
const returnMoney=(params)=>postAction(baseURL+"/returnMoney",params);
//补录
const clearingMoney=(params)=>postAction(baseURL+"/clearingMoney",params);
//
const getNoRetuenById=(params)=>getByIdAction(baseURL+"/getNoRetuenById",params);

const getCancelMoney=(params)=>getByIdAction(baseURL+"/getCancelMoney",params);
//查看差异金额
const getDifferMoneyList=(params)=>getAction(baseURL+"/getDifferMoneyList",params);


const getNormalBorrowAll=(params)=>getAction(baseURL+"/getNormalBorrowAll",params)

export {
    borrowList,
    addBorrow,
    cancellMoney,
    selectBorrowById,
    getCancellById,
    editBorrow,
    returnMoney,
    clearingMoney,
    getNoRetuenById,
    getCancelMoney,
    getDifferMoneyList,
    getNormalBorrowAll
}
