<template>
  <el-dialog title="借用查看流水" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-table :data="BorrowLogFrom" border stripe style="width: 100%" show-summary>
      
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="支行名称" prop="subbranch" />
      <el-table-column label="借走人别名" prop="borrowAlias" />
      <el-table-column label="借走资金" prop="borrowMoney" />
      <el-table-column label="借走日期" prop="borrowData" />
      <el-table-column label="借走备注" prop="borrowRemark" />
      <el-table-column label="预计归还时间" prop="preReturnData" />
    </el-table>
  </el-dialog>
</template>
<script>
import {
  getCancellById,
  getNoRetuenById,
  getNormalBorrowAll,
  getDifferMoneyList
} from "@/api/capital/bank/bankBorrow";
export default {
  name: "borrowLog",
  data() {
    return {
      dialogVisible: false,
      BorrowLogFrom: [],
      queryQuerm: {}
    };
  },
  methods: {
    //打开弹窗
    show(id, type) {
      this.dialogVisible = true;
      if (type == 1) {
        this.getCancellByIdButton(id);
      }
      if (type == 2) {
        this.getNormalBorrowAllButton(id, null);
      }
      if (type == 3) {
        this.getNoCancellByIdButton(id);
      }
      if(type==4){
        this.getDifferMoneyListButton(id)
      }
    },
    getNoCancellByIdButton(id) {
      getNoRetuenById(id).then(res => {
        console.log(res);
        this.BorrowLogFrom = res.result;
      });
    },
    getNormalBorrowAllButton(id, type) {
      this.queryQuerm.id = id;
      this.queryQuerm.borrowStatus = type;
      getNormalBorrowAll(this.queryQuerm).then(res => {
        this.BorrowLogFrom = res.result;
      });
    },
    getDifferMoneyListButton(id) {
      this.queryQuerm.id=id
      getDifferMoneyList(this.queryQuerm).then(res => {
        this.BorrowLogFrom = res.result;
      });
    },
    getCancellByIdButton(id) {
      getCancellById(id).then(res => {
        console.log(res);
        this.BorrowLogFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script>