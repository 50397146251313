import request from '@/utils/axios'
//需要次级封装才行  
// import { axios } from '@/utils/request'

export function getAction(url, parameter, headers) {
    if (!headers) {
        headers = {}
    }
    return request({
        url: url,
        method: 'get',
        params: parameter,
        headers: headers
    })
}

export function getByIdAction(url, id, headers) {
    if (!headers) {
        headers = {}
    }
    return request({
        url: url + '?id=' + id,
        method: 'get',
        headers: headers
    })
}

export function getByTypeAction(url, type, headers) {
    if (!headers) {
        headers = {}
    }
    return request({
        url: url + '?type=' + type,
        method: 'get',
        headers: headers
    })
}

//post
export function postAction(url, parameter, headers) {
    if (!headers) {
        headers = {}
    }
    return request({
        url: url,
        method: 'post',
        data: parameter,
        headers: headers,
        traditional: true
    })
}

// { 'Content-Type': 'application/json' }
// //post method= {post | put}
// export function httpAction(url, parameter, method) {
//     return request({
//         url: url,
//         method: method,
//         data: parameter
//     })
// }

// //POST
// export function httpContract(url, parameter, method) {
//     return request({
//         url: url,
//         method: method,
//         data: parameter,
//     })
// }

//put
export function putAction(url, parameter) {
    return request({
        headers: {
            'Content-Type': 'application/json'
        },
        url: url,
        method: 'put',
        // 'Content-Type': 'application/json',
        data: parameter
    })
}

//deleteAction
export function deleteAction(url, parameter) {
    return request({
        url: url,
        method: 'delete',
        params: parameter
    })
}

//deleteByIdAction
export function deleteByIdAction(url, id, headers) {
    if (!headers) {
        headers = {}
    }
    return request({
        url: url + '?id=' + id,
        method: 'delete',
        headers: headers
    })
}


export function uploadFileProgress(url, file, fileName, onUploadProgress) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        fileName = fileName == undefined ? 'file' : fileName;
        formData.append(fileName, file);
        return request({
            method: 'post',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                if (onUploadProgress) {
                    onUploadProgress(progressEvent)
                }
            }
        }).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        });
    })
}


export function uploadFile(url, file, fileName) {
    let formData = new FormData();
    fileName = fileName == undefined ? 'file' : fileName;
    formData.append(fileName, file);
    return request({
        method: 'post',
        url: url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}


export function uploadFileFormProgress(url, formData, onUploadProgress) {
    return new Promise((resolve, reject) => {
        return request({
            method: 'post',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                if (onUploadProgress) {
                    onUploadProgress(progressEvent)
                }
            }
        }).then(res => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        });
    })
}
